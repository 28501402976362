import React, { useState, useEffect } from "react";
import { Button, Input, Label, Col, Row, Form } from "reactstrap";

interface teacherType {
  name: string;
  email: string;
}

const EditTeachers = () => {
  function getTeachers() {
    fetch(`${window.env.API_URL}/api/teachers`, {
      method: "GET",
    })
      .then((response) => response.json())
      .then((data) => setTeacherList(data));
  }

  function deleteTeacher(name: string, email: string) {
    fetch(`${window.env.API_URL}/api/teachers`, {
        method: "DELETE",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          name: name,
          email: email,
        }),
      }).then(() => getTeachers());
  }

  function addTeacher(name: string, email: string) {
    fetch(`${window.env.API_URL}/api/teachers`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        name: name,
        email: email,
      }),
    }).then(() => getTeachers());
    setName("");
    setEmail("");
  }

  const [name, setName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [teacherList, setTeacherList] = useState<teacherType[]>([]);

  useEffect(() => {
    getTeachers();
  }, []);

  return (
    <div className="game-init-layout">
      <h1 className="game-init-welcome-heading">Õpetajate andmed</h1>
      <Form style={{ width: "100%" }}>
        <Row>
          <Col>
            <Label className="game-init-form__label">ÕPETAJA NIMI</Label>
          </Col>
          <Col>
            <Label className="game-init-form__label">ÕPETAJA EMAIL</Label>
          </Col>
          <Col></Col>
        </Row>
        {teacherList.map((t) => (
          <Row style={{ paddingBottom: "10px" }}>
            <Col>
              <Input
                disabled
                style={{ color: "black" }}
                value={t.name}
                type="text"
              />
            </Col>
            <Col>
              <Input
                disabled
                style={{ color: "black" }}
                value={t.email}
                type="text"
              />
            </Col>
            <Col>
              <Button color="danger" onClick={() => deleteTeacher(t.name, t.email)}>Kustuta</Button>
            </Col>
          </Row>
        ))}
        <Row style={{ paddingBottom: "10px" }}>
          <Col>
            <Input
              style={{ color: "black" }}
              value={name}
              onChange={(e) => setName(e.target.value)}
              type="text"
            />
          </Col>
          <Col>
            <Input
              style={{ color: "black" }}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              type="text"
            />
          </Col>
          <Col>
            <Button color="primary" onClick={() => addTeacher(name, email)}>
              Lisa
            </Button>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default EditTeachers;
